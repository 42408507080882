import React, { useState, useEffect } from "react"
import DataReport from "./datareport"
import ApprovalReport from "./approvalreport"
import sanityClient from "@sanity/client"
import dayjs from "dayjs"
import { Button } from "@mui/material"

import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker"
import * as FileSaver from "file-saver"
import * as XLSX from "xlsx"
import { auth } from "../firebase/config"

const client = sanityClient({
  projectId: "3g2qblp5",
  dataset: "production",
  apiVersion: "2021-10-21",
  useCdn: false,
  token: process.env.REACT_APP_SANITY,
})

let admin = await client.fetch(`*[_type == "admin"]`)

// let kill = await client.delete({
//   query: `*[_type == "sales"]`,
// })
// console.log("kill", kill)

const Approval = () => {
  const [incentive, setIncentive] = useState([])
  const [count, setCount] = useState(0)
  const [value, setValue] = React.useState(
    dayjs(
      new Date().toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
      })
    )
  )

  useEffect(() => {
    console.log(value.toISOString().split("T")[0])
    const data = async () => {
      let name = admin.filter(
        (item) => item.email === auth.currentUser.email
      )[0].name
      let email = admin.filter(
        (item) => item.email === auth.currentUser.email
      )[0].email
      console.log(name, email)
      let query = `*[_type == "sales" && status != "rejected" && approved != true && bill_signedby in ["${name}", "${email}"]] | order(_createdAt)`
      const productlist_response = await client.fetch(query)
      setIncentive(productlist_response)
    }
    data()
  }, [count, auth.currentUser])

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
  const fileExtension = ".xlsx"

  console.log(incentive)

  let array = []

  incentive.map((item) => {
    let obj = {
      serial_no: item.serial_no,
      date: item._createdAt,
      bill_series: item.bill_series,
      bill_no: item.bill_no,
      name: item.sales_man,
      brand: item.brand,
      product: item.product,
      model: item.model_no,
      sale_amount: item.sale_amount,
      mop: item.item_mop,
      sp: item.item_sp,
      lp: item.item_lp,
      nlc: item.nlc,
      achieved_slab: item.achieved_slab,
      incentive: item.achieved_slab_amount,
      quantity: item.quantity,
      total_Incentive: item.achieved_slab_amount * item.quantity,
      margin: (item.sale_amount - item.nlc) * item.quantity,
      exchange: item.exchange_product,
      status: item.status,
      bill_signedby: item.bill_signedby,
      remarks: item.remarks,
    }
    array.push(obj)
  })

  const exportToCSV = (fileName) => {
    const ws = XLSX.utils.json_to_sheet(array)
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          justifyContent: "center",
          marginLeft: "20%",
          marginRight: "20%",
        }}
      >
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["StaticDatePicker"]}>
            <StaticDatePicker
              value={value}
              onChange={(value) => {
                let date = new Date(value)
                console.log("date", date)
                setValue(date)
              }}
            />
          </DemoContainer>
        </LocalizationProvider> */}
      </div>
      <Button
        variant="contained"
        style={{ marginBottom: "10px", backgroundColor: "#28282B" }}
        onClick={(e) => exportToCSV("data")}
      >
        EXPORT REPORT
      </Button>
      <ApprovalReport
        productlist={incentive}
        setCount={setCount}
        count={count}
      />
    </div>
  )
}

export default Approval
